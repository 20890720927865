.App {
  text-align: center;
}

.box1 {
  background-color: white;
  border: 1px solid silver;
}

.box2 {
  background-color: #002776;
  border: 1px solid silver;
}

.brasil-verde {
  color: #009c3b;
}

.brasil-amarelo {
  color: #ffdf00;
}

.brasil-azul {
  color: #002776;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}

.flex-item {
  align-content: center;
  padding: 5px;
  width: 20vw;
  min-width: 15rem;
  max-width: 28rem;
  aspect-ratio: 1/1;
  /* height: 28rem;
  height: 32rem; */
  color: white;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.nowrap {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.wrap li {
  border: 0.05rem solid silver;
}

.wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.wrap-reverse li {
  background: deepskyblue;
}

.central {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #010b1f;
}

.central > div {
  flex: auto;
}

.text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24pt;
  color: #eeeeee;
  margin-top: 1rem;
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
